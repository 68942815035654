import {lazy, Suspense} from 'react';
import {Navigate} from "react-router-dom";

import Result403 from "../components/403";
import Result404 from "../components/404";
import Result500 from "../components/500";
// import Fallback from "../components/loading";


const Fallback = () => {
    return <div></div>
}

const Login = lazy(() => import('../page/Login'));
const Home = lazy(() => import('../page/Home'));
const User = lazy(() => import('../page/Home/User'));
const Group = lazy(() => import('../page/Home/Group'));
const Menu = lazy(() => import('../page/Home/Menu'));
const MenuItem = lazy(() => import('../page/Home/MenuItem'));
const Build = lazy(() => import('../page/Home/Build'));
const Device4G = lazy(() => import('../page/Home/Device4G'));
const DeviceLora = lazy(() => import('../page/Home/DeviceLora'));
const DeviceGather4G = lazy(() => import('../page/Home/DeviceGather4G'));
const DeviceGatherLora = lazy(() => import('../page/Home/DeviceGatherLora'));

const ScheduledTasks = lazy(() => import('../page/Home/ScheduledTasks'));

const SituationPage = lazy(() => import('../page/Home/SituationPage'));
const SituationPageYCB = lazy(() => import('../page/Home/SituationPageYCB'));
// const SituationPage = lazy(() => import('../page/Home/SituationPage'));

const Acpc = lazy(() => import('../page/Home/Acpcc'));
const AcpccGather = lazy(() => import('../page/Home/AcpccGather'));

const YCB = lazy(() => import('../page/Home/YCB'));
const YCBGather = lazy(() => import('../page/Home/YCBGather'));


const routes = [
    {
        path: "/",
        element: <Suspense fallback={<Fallback/>}><Login/></Suspense>,
    },
    {
        path: "/login",
        element: <Suspense fallback={<Fallback/>}><Login/></Suspense>,
    },
    {
        path: "/device/4g/gather/:id",
        element: <Suspense fallback={<Fallback/>}><DeviceGather4G/></Suspense>,
    },
    {
        path: "/device/acpcc/gather/:id",
        element: <Suspense fallback={<Fallback/>}><AcpccGather/></Suspense>,
    },
    {
        path: "/device/ycb/gather/:id",
        element: <Suspense fallback={<Fallback/>}><YCBGather/></Suspense>,
    },
    // {
    //     path: "/device/lora/gather/:id",
    //     element: <Suspense fallback={<Fallback/>}><DeviceGatherLora/></Suspense>,
    // },
    {
        path: "/home",
        element: <Suspense fallback={<Fallback/>}><Home/></Suspense>,
        children: [
            {
                path: "",
                element: <Suspense fallback={<Fallback/>}><SituationPage/></Suspense>,
            },
            {
                path: "situationPage",
                element: <Suspense fallback={<Fallback/>}><SituationPage/></Suspense>,
            },
            {
                path: "ycb/situationPage",
                element: <Suspense fallback={<Fallback/>}><SituationPageYCB/></Suspense>,
            },
            {
                path: "User",
                element: <Suspense fallback={<Fallback/>}><User/></Suspense>,
            },
            {
                path: "Group",
                element: <Suspense fallback={<Fallback/>}><Group/></Suspense>,
            },
            {
                path: "Menu",
                element: <Suspense fallback={<Fallback/>}><Menu/></Suspense>,
            },
            {
                path: "MenuItem/:id",
                element: <Suspense fallback={<Fallback/>}><MenuItem/></Suspense>,
            },
            {
                path: "Build",
                element: <Suspense fallback={<Fallback/>}><Build/></Suspense>,
            },
            {
                path: "device/4g",
                element: <Suspense fallback={<Fallback/>}><Device4G/></Suspense>,
            },
            {
                path: "device/ycb",
                element: <Suspense fallback={<Fallback/>}><YCB/></Suspense>,
            },
            // {
            //     path: "device/lora",
            //     element: <Suspense fallback={<Fallback/>}><DeviceLora/></Suspense>,
            // },
            {
                path: "device/acpcc",
                element: <Suspense fallback={<Fallback/>}><Acpc/></Suspense>,
            },
            {
                path: "scheduledTasks",
                element: <Suspense fallback={<Fallback/>}><ScheduledTasks/></Suspense>,
            },
        ]
    },
    {
        path: "/401",
        element: <div>无权限...</div>
    },
    {
        path: "/403",
        element: <Result403/>
    },
    {
        path: "/404",
        element: <Result404/>
    },
    {
        path: "/500",
        element: <Result500/>
    },
    {
        path: "*",
        element: <Result404/>
    },
    {
        path: "*",
        element: <Navigate to="/404"></Navigate>
    },
]
export default routes;